@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");

.wa_bg{
    height:350px;
    background: #ECE5DD;
    font-family: "Helvetica Neue", Helvetica, serif;
}
.msg {
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden;
    opacity: 0;
    animation: fadeIn .5s forwards;
}
.msg .bubble {
    float: left;
    max-width: 90%;
    width: auto;
    height: auto;
    display: block;
    background: white;
    border-radius: 5px;
    position: relative;
    margin: 10px 0 3px 25px;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}
@media (max-width: 576px) {
    .msg .bubble {
        max-width: 65%;
        margin: 10px 0 3px 65px;
    }
    .msg .bubble.alt {
        margin: 10px 68px 3px 0!important;
    }
    .c-wa-audio__time-container {
        margin-right: 100px!important;
        margin-left: 10px!important;
    }
}
@media (min-width: 768px) {

}
@media (min-width: 992px) {

}
.msg .bubble.alt {
    margin: 10px 25px 3px 0;
    background: #DCF8C6;
    float: right;
}
.msg .message.alt {
    padding: 0 45px 0 15px!important;

}
.msg .bubble.follow {
    margin: 2px 0 3px 25px;
}
.msg .bubble.altfollow {
    margin: 2px 25px 3px 0;
    background: #DCF8C6;
    float: right;
}
.msg .bubble .txt {
    padding: 8px 0 8px 0;
    width: 100%;
}
.msg .bubble .txt .name {
    font-weight: 600;
    font-size: 14px;
    display: inline-table;
    padding: 0 0 0 15px;
    margin: 0 0 4px 0;
    color: #3498db;
}
.msg .bubble .txt .name span {
    font-weight: normal;
    color: #b3b3b3;
    overflow: hidden;
}
.msg .bubble .txt .name.alt {
    color: #2ecc51;
}
.msg .bubble .txt .message {
    font-size: 14px;
    font-weight: 500;
    padding: 0 15px 0 15px;
    margin: auto;
    color: #2b2b2b;
    display: table;
}
.msg .bubble .txt .timestamp {
    font-size: 11px;
    margin: auto;
    padding: 0 15px 0 0;
    display: table;
    float: right;
    position: relative;
    text-transform: uppercase;
    color: #999;
}
.msg .bubble .bubble-arrow {
    position: absolute;
    float: left;
    left: -11px;
    top: 0px;
}
.msg .bubble .bubble-arrow.alt {
    bottom: 20px;
    left: auto;
    right: 4px;
    float: right;
}
.msg .bubble .bubble-arrow:after {
    content: "";
    position: absolute;
    border-top: 15px solid white;
    border-left: 15px solid transparent;
    border-radius: 4px 0 0 0px;
    width: 0;
    height: 0;
}
.msg .bubble .bubble-arrow.alt:after {
    border-top: 15px solid #DCF8C6;
    transform: scaleX(-1);
}

.c-wa-message {
    padding: 7px 14px 6px;
    background-color: #fff;
    border-radius: 0 8px 8px;
    position: relative;
    transition: all 0.3s ease 0s;
    box-shadow: rgba(0, 0, 0, 0.13) 0 1px .5px;
    margin-top: 4px;
    margin-left: -54px;
}
.c-wa-message:before {
    position: absolute;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACQUExURUxpccPDw9ra2m9vbwAAAAAAADExMf///wAAABoaGk9PT7q6uqurqwsLCycnJz4+PtDQ0JycnIyMjPf3915eXvz8/E9PT/39/RMTE4CAgAAAAJqamv////////r6+u/v7yUlJeXl5f///5ycnOXl5XNzc/Hx8f///xUVFf///+zs7P///+bm5gAAAM7Ozv///2fVensAAAAvdFJOUwCow1cBCCnqAhNAnY0WIDW2f2/hSeo99g1lBYT87vDXG8/6d8oL4sgM5szrkgl660OiZwAAAHRJREFUKM/ty7cSggAABNFVUQFzwizmjPz/39k4YuFWtm55bw7eHR6ny63+alnswT3/rIDzUSC7CrAziPYCJCsB+gbVkgDtVIDh+DsE9OTBpCtAbSBAZSEQNgWIygJ0RgJMDWYNAdYbAeKtAHODlkHIv997AkLqIVOXVU84AAAAAElFTkSuQmCC);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    top: 0;
    left: -12px;
    width: 12px;
    height: 19px;
}

.c-wa-audio{
    padding-left:10px
}
.c-wa-audio__wrapper {
    display: flex;
    align-items: center;
    width: 350px;
}
.c-wa-audio__photo {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPCFET0NUWVBFIHN2ZyAgUFVCTElDICctLy9XM0MvL0RURCBTVkcgMS4xLy9FTicgICdodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQnPgo8c3ZnIHdpZHRoPSI0MDFweCIgaGVpZ2h0PSI0MDFweCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAzMTIuODA5IDAgNDAxIDQwMSIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIzMTIuODA5IDAgNDAxIDQwMSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgdHJhbnNmb3JtPSJtYXRyaXgoMS4yMjMgMCAwIDEuMjIzIC00NjcuNSAtODQzLjQ0KSI+Cgk8cmVjdCB4PSI2MDEuNDUiIHk9IjY1My4wNyIgd2lkdGg9IjQwMSIgaGVpZ2h0PSI0MDEiIGZpbGw9IiNFNEU2RTciLz4KCTxwYXRoIGQ9Im04MDIuMzggOTA4LjA4Yy04NC41MTUgMC0xNTMuNTIgNDguMTg1LTE1Ny4zOCAxMDguNjJoMzE0Ljc5Yy0zLjg3LTYwLjQ0LTcyLjktMTA4LjYyLTE1Ny40MS0xMDguNjJ6IiBmaWxsPSIjRkZGRkZGIi8+Cgk8cGF0aCBkPSJtODgxLjM3IDgxOC44NmMwIDQ2Ljc0Ni0zNS4xMDYgODQuNjQxLTc4LjQxIDg0LjY0MXMtNzguNDEtMzcuODk1LTc4LjQxLTg0LjY0MSAzNS4xMDYtODQuNjQxIDc4LjQxLTg0LjY0MWM0My4zMSAwIDc4LjQxIDM3LjkgNzguNDEgODQuNjR6IiBmaWxsPSIjRkZGRkZGIi8+CjwvZz4KPC9zdmc+Cg==);
    background-position: center;
    background-size: cover;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 1px solid #ddd;
}
.c-wa-audio__photo-container {
    position: relative;
}
.c-wa-audio__photo-mic {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 1.1rem;
    color: #999999;
    filter: drop-shadow(1px 1px 0 white) drop-shadow(-1px -1px 0 white);
}
.c-wa-audio__control-play {
    color: #999999;
    margin-left: 20px;
    cursor: pointer;
    font-size: 1.1rem;
}
.c-wa-audio__time-container {
    font-size: 16px;
    line-height: 18px;
    color: #55606e;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    margin-left: 24px;
    margin-right: 24px;
}
.c-wa-audio__time-slider {
    flex-grow: 1;
    background-color: #d8d8d8;
    cursor: pointer;
    position: relative;
    margin-left: 16px;
    margin-right: 16px;
    border-radius: 2px;
    height: 4px;
}
.c-wa-audio__time-progress {
    background-color: #2ab5eb;
    border-radius: inherit;
    position: absolute;
    pointer-events: none;
    height: 4px;
}
.c-wa-audio__time-pin {
    height: 13px;
    width: 13px;
    border-radius: 50%;
    background-color: #2ab5eb;
    position: absolute;
    pointer-events: all;
    top: -4px;
    right: -10px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.32);
}
.first{animation-delay: 0s;}
.second{animation-delay: .9s;}
.third{animation-delay: 1.2s;}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}