:root {
    *--background-color: #fafafa;
    --background-color: #ffffff;
    --main-color: rgb(0, 0, 0);
    --btn-border-color: rgb(16, 34, 252);
    --btn-text-color: rgb(16, 134, 252);
    --btn-background-color: rgb(242, 249, 255);
    --btn-border-color-hover: rgb(0, 50, 101);
    --btn-text-color-hover: rgb(0, 50, 101);
    --btn-background-color-hover: rgb(154, 209, 255);
    --success-background: #F1FFF8;
    --danger-background: #fff1f1;
    --error-text: #DB2742;
    --error-text-hover: #a41c30;
    --error-text-active: #a41c30;
    --success-text: #4ceb9c;
    --success-text-hover: #3dbd7d;
    --success-text-active: #2f9863;
    --success-button-text : #004020;
    --danger-button-text : #400000;
    --description-text: #3C3C3C;
    --disabled-text: #cecece;
    --toastify-color-success: #4ceb9c!important;
    --toastify-icon-color-success: white!important;
}

@font-face {
    font-family: 'Sophia Nubian Regular';
    src: local('snr'),
    url("./assets/fonts/SNR.ttf") format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Sophia Nubian Bold';
    src: local('snb'),
    url("./assets/fonts/SNB.ttf") format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Sophia Nubian Italic';
    src: local('snr'),
    url("./assets/fonts/SNI.ttf") format('truetype');
    font-weight: auto;
}

@font-face {
    font-family: 'Sophia Nubian Bold Italic';
    src: local('snbi'),
    url("./assets/fonts/SNBI.ttf") format('truetype');
    font-weight: bold;
}
@font-face {
    font-family: 'Roboto Thin';
    src: local('roboto-thin'),
    url("./assets/fonts/Roboto-Thin.ttf") format('truetype');
    font-weight: auto;
}
@font-face {
    font-family: 'Roboto Regular';
    src: local('roboto-regular'),
    url("./assets/fonts/Roboto-Regular.ttf") format('truetype');
    font-weight: auto;
}
body {
    margin: 0;
    background: var(--background-color);
    font-family: "Sophia Nubian Regular", serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.Toastify__toast-theme--light{
    color: var(--description-text)!important;
    font-family: "Sophia Nubian Bold", serif!important;
}
