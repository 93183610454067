.head-navigation{
    font-family: "Sophia Nubian Regular", serif;
    background: #fafafa;
    border-bottom:1px solid #a1a1a1;
    display: flex;
    align-items: center;
    padding: 6px 6px 6px 18px;
}
.head-navigation a{
    color:black;
    margin-top:0;
    margin-right: 10px;
    margin-left: 10px;
}
.head-navigation span{
    font-size: .8em;
    margin-left:10px;
    font-family: "Sophia Nubian Bold", serif;

}
.head-nav-div{
    display: flex;
}
