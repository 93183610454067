/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 40px;
    left: 18px;
    top:15px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: white;
    border-radius: 30px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: white!important;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: white;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    overflow: hidden;
}

/* General sidebar styles */
.bm-menu {
    background: var(--main-color);
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    overflow: hidden!important;
}
/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}
a{
    margin-top:5px;
    font-family: "Sophia Nubian Regular",serif;
    font-size: .8em;
    color:white;
    text-decoration: none;
}
.navigation{
    font-size: .6em;
}
