.border-custom {
    border: 2px solid #E6E6E6;
    border-radius: 8px;
}

h5 {
    color: var(--btn-text-color) !important;
    line-height: 0 !important;
}
.disabled {
    h5{
        color: var(--disabled-text) !important;
    }
    .description {
        color: var(--disabled-text);
    }

}
.color-disabled{
    color: var(--disabled-text)
}
.border-custom.disabled:hover {
    border-color: #E6E6E6!important;
}
.border-custom.disabled {
    border: 2px solid #E6E6E6!important;
}
a.disabled{
    cursor: not-allowed!important;
}
.description {
    color: var(--description-text);
    font-size: 14px;
}

a.border-custom {
    display: block;
}

a.border-custom:hover {
    border-color: #cccccc;
}