.c-button {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Sophia Nubian Bold", serif;
    font-size: .8em;
    background: var(--btn-background-color);
    color: var(--btn-text-color);
    border:1px solid var(--btn-border-color);
    border-radius: 5px;
    padding: .4em .1em .4em 1em;
    display: inline-block;
    align-items: center;
    cursor: pointer;
}
.c-button svg{
    font-size: 1.4em;
    margin-right:.5em;
    float:left;
    margin-left:20px ;
}
.c-button div{
    float:left
}
.c-button:disabled{
    background: #dadada;
    color: #aaa;
    border-color:#aaa;
}
.c-button:hover{
    background: var(--btn-background-color-hover);
    color: var(--btn-text-color-hover);
    border:1px solid var(--btn-border-color-hover);
}
.c-button:disabled:hover{
    background: #dadada;
    color: #9d9d9d;
}
@media screen and (max-width: 768px) {
    .c-button div{
        display: none;
    }
    .c-button svg{
        margin-left:10px;
    }
}

