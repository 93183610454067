.modal {
    max-height: 50%;
    max-width: 30%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: white;
    padding: 3% 4% 2% 4%;
    display: flex;
    justify-content: center;
}

.display-flex {
    display: flex;
}

.flex-50 {
    flex-basis: 50%;
}

.flex-50 input {
    margin: 0 5px 0 5px;
}

.flex-50 {
    padding-right: 5px;
}
.pointer{
    cursor: pointer;
}

.center-switch {
    display: flex;
    justify-content: center;
}

.holiday-information {
    font-size: .8em;
    color: #666;
}

.flex-item {
    display: flex;
    align-items: center;
}

.switch-margin {
    margin-top: 20px;
}

.modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.delete-container {
    flex-direction: row;
}

.modal-container label {
    font-family: "Sophia Nubian Regular", serif;
    color: #666;
    font-size: .7em;
}

.modal-container input {
    font-family: "Sophia Nubian Regular", serif;
    border: 1px solid #e6e6e6;
    margin-bottom: 10px;
}

.modal-container input::placeholder {
    font-family: "Sophia Nubian Regular", serif;
    font-size: .9em;
    color: #aaa;
}

.modal-container input[type="color"] {
    -webkit-appearance: none;
    border: none;
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    background: transparent;
}

.modal-container svg {
    font-size: 3em;
    color: #3C3C3C;
}

.modal-container h2 {
    color: #3C3C3C
}

.modal-container img {
}

.modal-container h2 {
    font-size: 1.2em;
}

.errorMessage {
    margin-top: 15px;
    font-size: .8em;
    text-align: center;
    font-family: "Sophia Nubian Regular", serif;
    color: var(--error-text)
}

.modal-container-button {
    width: 100%;
    height: 40px;
    font-size: .9em;
    background: var(--success-text);
    color: var(--success-button-text);
    border: none;
    font-family: "Sophia Nubian Bold", serif;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 25px;
}
.delete-button{
    width: 100%;
    height: 40px;
    font-size: .9em;
    background: var(--error-text);
    color: var(--danger-button-text);
    border: none;
    font-family: "Sophia Nubian Bold", serif;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 5px;
}
.modal-divider{
    width:70px;
    border-bottom: 1px solid #aaa;
    text-transform: uppercase;
    color:#aaa;
    font-size: .8em;
    margin:20px;
}
.holiday-divider{
    width:40px;
    height:1px;
    justify-content: center;
    background: #aaa;
}
.modal-delete-button {
    width: 40%;
    height: 40px;
    font-size: .9em;
    border: none;
    font-family: "Sophia Nubian Bold", serif;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
}

.confirm-btn {
    background: var(--success-text);
    color: var(--success-button-text);
}

.confirm-btn:hover {
    background: var(--success-text-hover);
}
.confirm-btn:active {
    background: var(--success-text-active);
}

.reject-btn {
    background: var(--error-text);
    color: var(--danger-button-text);
}

.reject-btn:hover {
    background: var(--error-text-hover);
}
.reject-btn:active {
    background: var(--error-text-active);
}

.grid-item {
    display: flex;
    justify-content: space-between;
    font-size: .95em;
}
.grid-item-start{
    padding-left: 20px;
    padding-right: 20px;
}
.border-holiday-information{
    padding:10px;
    border: 1px solid black;
    border-radius: 10px;
}
.overlay {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.top-right {
    margin: 20px;
    top: 0;
    padding-right:10px;
}

.color--delete svg {
    font-size: 2em;
    color: var(--error-text);
}
.position-absolute svg{
    fill: #aaa;
}

.selected {
    display: flex;
    flex-wrap: wrap;
    font-family: "Sophia Nubian Regular", serif;
}

.selected-flex-item {
    flex-basis: 50%;
}

.selected-name {
    flex-basis: 100%;
    font-family: "Sophia Nubian Bold", serif;
    color: #3C3C3C;
}

.selected-title {
    font-size: .7em;
    color: #666;
}

.selected-value {
    font-size: .8em;
}

.change-federals-disclaimer {
    font-size: .8em;
    text-align: center;
    color: #666;
}

@media screen and (max-width: 768px) {
    .modal-container h2 {
        font-size: 1.1em;
    }

    .modal {
        max-height: 80%;
        min-width: 80%;
        padding: 8% 8% 10% 8%;
    }
}

@media only screen
and (min-device-width: 834px)
and (max-device-width: 1112px)
and (-webkit-min-device-pixel-ratio: 2) {
    .modal {
        max-height: 60%;
        min-width: 30%;
    }
}
