.card-header-logo{
    position: absolute;
    border-radius: 100%;
    padding:5%;
    left:50%;
    top:40%;
    margin-top:-10px;
    transform: translate(-50%, -50%);
    background: var(--background-color);
}

.btn-gear:hover{
    background: none!important;
    color:var(--btn-text-color);
}
.btn-gear:focus{
    background: none!important;
    color:var(--btn-text-color);
}
.btn-gear:not(:disabled){
    background: none!important;
    border:none!important;
}

@keyframes expand {
    from {
        transform: scaleY(0);
        opacity: 0;
    }
    to {
        transform: scaleY(1);
        opacity: 1;
    }
}

@keyframes collapse {
    from {
        transform: scaleY(1);
        opacity: 1;
    }
    to {
        transform: scaleY(0);
        opacity: 0;
    }
}

.expand {
    transform-origin: top;
    animation: expand 0.5s ease-out forwards;
    overflow: hidden;
}
.mt--1{
margin-top: 2.1px;
}
.collapse {
    transform-origin: top;
    animation: collapse 0.5s ease-out forwards;
    overflow: hidden;
    opacity: 0;
}

._dropdown{

}