.btn {
    font-family: "Sophia Nubian Regular", serif;
    display: inline-block;
    padding: 4px 10px 4px;
    margin-bottom: 0;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    vertical-align: middle;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 0;
}

.btn:hover,
.btn:active,
.btn.active,
.btn.disabled,
.btn[disabled] {
    background-color: #e6e6e6;
}

.btn-large {
    padding: 9px 14px;
    font-size: 15px;
    line-height: normal;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.btn-primary,
.btn-primary:hover {
    color: #ffffff;
}

.btn-primary {
    background-color: var(--main-color);
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary.active,
.btn-primary.disabled,
.btn-primary[disabled] {
    filter: none;
    background-color: white;
    color: var(--main-color)
}

.btn-social-login {
    position: relative;
    display: flex;
    text-align: center;
    background-color: white;
}

.btn-social-login svg {
    position: absolute;
    justify-content: center;
    top: 15%;
    left: 5%;
    align-items: center;
}

.btn-social-login path {
    fill: var(--main-color)
}

.btn-social-login:hover,
.btn-social-login:active,
.btn-social-login.active{
    filter: none;
    *background-color: var(--main-color);
    *color: white
}
.btn-social-login[disabled] path {
    fill: rgba(0,0,0,.2)
}
.btn-social-login.disabled,
.btn-social-login[disabled] {
    background: white;
    border-color: rgba(0,0,0,.2);
    color: rgba(0,0,0,.2);
}
.errorCode{
    font-family: "Sophia Nubian Bold", serif;
    margin-bottom:10px;
    font-size: .7em;
    min-height: 20px;
    color: var(--error-text)
}

.btn-block {
    width: 100%;
    display: block;
    border: 2px solid var(--main-color);
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
}


.login {
    position: absolute;
    top: 40%;
    left: 50%;
    margin: -150px 0 0 -150px;
    width: 300px;
    height: 300px;
}

.login h1 {
    color: var(--main-color);
    text-align: center;
}

input {
    font-family: "Sophia Nubian Bold", serif;
    width: 100%;
    margin-bottom: 0;
    outline: none;
    padding: 10px;
    font-size: .9em;
    color: var(--main-color);
    border: 2px solid var(--main-color);
    border-radius: 4px;
}
input:nth-child(2){
    margin-bottom: 2px;
}
