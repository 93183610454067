.grid {
    font-family: "Sophia Nubian Regular", serif;
    color: #3C3C3C;
    padding: 2% 5%;
    display: grid;
    grid-template-columns: .6fr 2fr;
    grid-template-rows: auto;
    gap: 40px;
}

.grid-item1 {
    grid-column: 1 / 2;
}

.grid-item2 {
    grid-column: 2 / 3;
    display: grid;
    grid-template-rows: auto;
    gap: 200px;
}
.react-switch{
}

.grid-item2-container {
    display: grid;
    grid-template-rows: auto;
    gap: 20px;
}
.flex-row-container{
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
    margin:15px;
    padding-bottom: 15px;
    font-size: .8em;
}
.flex-container-employee{
    margin:15px;
}
.employee-color{
    border-radius: 10px;
}
.border-bottom{
    border-bottom: 1px solid #e6e6e6;
}
.section-id{
    color: #aaa;
    overflow: hidden;
}

.section-id svg{
    color: #3C3C3C;
}
.position-absolute{
    position: absolute;
    right:0;
    margin-right:10px;
    cursor: pointer;
}
.position-absolute:hover{
    color:var(--btn-text-color)
}
.flex-item1{
    flex: 1 1 20%
}
.flex-item2{
    width: 50%;
    align-self: flex-start;
}
.flex-item2-toggle{
    width: 10%;
    align-self: flex-start;
}
.employee-card-content{
    display: flex;
    justify-content: center;
}
.employee-card{
    padding-bottom: 5%;
    position: relative;
}
.employee-card-header-logo{
    position: absolute;
    border-radius: 100%;
    padding:5%;
    left:50%;
    top:40%;
    margin-top:10px;
    transform: translate(-50%, -50%);
    background: var(--background-color);
}
.border{
    border: 1px solid #e6e6e6;
    border-radius: 7px;
}
.employee-card-header{
    display:flex;
    justify-content: space-between;
    padding:15px 15px 55px 15px;
    border-radius: 7px;
    background: #F5F5F5;
}
.employee-name{
    display: flex;
    margin-top:10%;
    padding:2%;
    font-family: "Sophia Nubian Bold", serif;
    color: #3C3C3C;
    justify-content: center;
    width:85%;
}
.badge{
    font-family: "Sophia Nubian Bold", serif;
    padding:3px 8px;
    font-size: .9em;
    border-radius: 20px;
}
.employee-active-status{
    color: var(--success-button-text);
    background: var(--success-background);
    border:2px solid var(--success-text);
}
.employee-inactive-status{
    color: var(--danger-button-text);
    background: var(--danger-background);
    border: 2px solid var(--error-text)
}

.content-name{
    font-family: "Sophia Nubian Bold", serif;
    color:#3C3C3C;
    border-bottom: 1px solid #e6e6e6;
    font-size: 2.6em;
}
.section-header{
    font-family: "Sophia Nubian Bold", serif;
    font-size: 1.1em;
}
.section-content:last-child{
    margin-bottom:50px;
}
@media screen and (max-width: 768px) {
    .grid {
        padding: 2% 5%;
        grid-template-columns: 1fr;
        gap: 40px;
    }
    th {
        max-width: 20px; /* Maximale Breite des <th>-Elements */
        white-space: nowrap; /* Verhindern, dass der Text umbricht */
        overflow: hidden; /* Text ausblenden, der über die maximale Breite hinausgeht */
        text-overflow: ellipsis; /* Auslassungszeichen (...) am Ende des abgeschnittenen Texts anzeigen */
    }

}
