.grid-padding {
    padding: 2% 5%;
}

.site-header {
    font-family: "Sophia Nubian Bold", serif;
    font-size: 1.3em;
    color: rgb(60, 60, 60);
    overflow: auto;
    margin-bottom: 20px;
}

.site-header span {
    font-family: "Sophia Nubian Regular", serif;
    font-size: .6em;
    color: rgb(98, 98, 98)
}

.site-header h1 {
    margin: 0;
    float: left;
}

.site-header button {
    margin-top: 15px;
    float: right;
}

@media screen and (max-width: 768px) {
    .c-button div {
        display: block;
    }
    .site-header {
        font-size: 1em;
    }

    .site-header span {
        font-size: .6em;
    }

    .site-header button {
        float: left;
        margin-left:0;
    }
}

table {
    width: 100%;
    border-spacing: 0 1rem;
    padding: 5px;
    margin-bottom:30px;
    border-collapse: separate!important;
}

thead {
    font-family: "Roboto Thin", serif;
    font-size: .8em;
    color: rgb(145, 145, 145);
}

th {
    text-align: left;
    padding-left: 20px;
}

.table-row {
    background: white;
    -webkit-appearance: none;
    box-shadow: 0 0 0 .125rem rgb(230, 230, 230);
    border-radius: 10px;
    cursor: pointer;
    font-family: "Sophia Nubian Regular", serif;
}

td {
    padding: 1.5%;
}

.table-absolute{
    position: absolute;
    left:0;
    top: 0;
    margin-left:5px;
}
.table-badge{
    font-family: "Sophia Nubian Bold", serif;
    padding:1px 4px;
    font-size: .5em;
    border-radius: 10px;
}
.border-radius-right {
    line-height: 0;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}
.is-active{
    color:var(--success-button-text);

}
.is-inactive{
    color:var(--danger-button-text);
}
.border-radius-left {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.table-row:hover {
    -webkit-appearance: none;
    background: rgb(242, 249, 255);
    box-shadow: 0 0 0 .125rem rgb(0, 123, 224);
}

tr{
    position: relative;
}
tr:hover td:last-child {
    color: var(--btn-text-color);
}
.flex {
    display: flex;
    align-items: center;
}

td:first-child span {
    line-height: 45px;
}

.circle-colored {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 10px;
}

.circle-colored div {
    position: absolute;
    color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1em;
    font-family: "Sophia Nubian Bold", serif;
}

.search-input {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 400px;
    background-color: white;
    border-radius: 5px;
    padding-left: 8px;
    padding-right: 8px;
    border: 1px solid rgb(230, 230, 230);
}

.search-input input::placeholder {
    font-family: "Roboto Regular", serif;
    color: #aaa;
}

.search-icon {
    margin-right: 8px;
    color: #aaa;
}

.delete-icon {
    margin-left: 8px;
    color: #aaa;
}

.employee-search {
    border: none;
}

.empty-employee-list-msg {
    padding: 0;
    color: #aaa;
    text-align: center;
}
