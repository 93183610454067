@keyframes loader_animate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes stroke_animate {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}

.loader_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 264px;
    height: 264px;
    overflow: hidden;
    position: relative;
}

.loader {
    animation: loader_animate 2s linear infinite;
}

.loader circle {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: stroke_animate 1.5s cubic-bezier(0.4, 0.0, 0.6, 1) infinite;
    stroke: #4ceb9c;
}
