.header {
    display: flex;
    justify-content: space-between;
    background-color: var(--main-color);
    color: white;
}
.acc-tier{
    border-radius: 15px;
    height:60%;
    font-size: 0.7em;
}
.acc-free{
    background-color: #1c1c1c;
    color: #ffe8bc;
}
.acc-premium{
    background-color: #1c1c1c;
    box-shadow: 0 0 10px 1px #149dff;
    color: #4ed9ff;
}
.acc-unlimited{
    background-color: #1c1c1c;
    box-shadow: 0 0 15px 1px #FF4914;
    color: #ffb42b;
}
.padding-left{
    padding-left: 40px;
}
.user-info {
    display: flex;
    padding: 1em 2em 1em 0;
}

.user {
    position: relative;
    display: flex;
    flex-direction: column;
}

.user-picture {
    width: 45px;
    position: relative;
    height: 45px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    margin-left: 2em;
    overflow: hidden;
    cursor: pointer;
}
.user-menu-container{
    position: absolute;
    left:20%;
    top:50px;
    z-index: 100;
    padding:10px;
    background: white;
    border:1px solid black;
}
.user-menu-container div{
    color:var(--error-text);
    font-size: 2em;
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.user-menu-container button{
    display: block;
    margin-top:10px;
    margin-bottom: 0;
    background: transparent;
    border:none;
    color: var(--error-text);
    text-decoration: underline;
    font-family: "Sophia Nubian Bold", serif;
    cursor: pointer;
}

.name {
    font-family: "Sophia Nubian Bold", serif;
}


.mail {
    font-family: "Sophia Nubian Regular", serif;
    font-size: .8em;
}
.user-missing-federal-warning{
    background: var(--error-text);
    display: flex;
    justify-content: center;
    font-size: .8em;
    color: white;
    font-family: "Sophia Nubian Bold", serif;
}
.online{
    color:greenyellow;
}
.offline{
    color: red;
}
.pab-1{
    padding-bottom: 1.5px;
}