.border-custom {
    border: 2px solid #E6E6E6;
    border-radius: 8px;
}
.price {
    margin: 24px 0;
    font-size: 36px;
    font-weight: 900;
}
.acc-unlimited-pricing{
    background-color: #1c1c1c;
    box-shadow: 0 0 20px 5px #FF4914!important;
    color: white;
}
.acc-unlimited-header{
    color: #ffb42b;
}
.acc-premium-pricing{
    box-shadow: 0 0 20px 5px #149dff!important;
    color: black;
}
.acc-premium-header{
    color: #4ed9ff;
}
.features-list {
    padding: 0;
    list-style-type: none;
}

.features-list li {
    font-weight: 100;
    padding: 12px 0;
}

.features-list li:not(:last-of-type) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.acc-unlimited-pricing > .features-list li:not(:last-of-type) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.pricing-box{
    font-family: 'Open Sans', sans-serif;
    box-shadow: 0 2px 15px 0 rgba(0,0,0,0.5);
    border-radius: 4px;
}
.price sub, .price sup {
    font-size: 16px;
    font-weight: 100;
}
h5 {
    color: var(--btn-text-color) !important;
    line-height: 0 !important;
}
.disabled {
    h5{
        color: var(--disabled-text) !important;
    }
    .description {
        color: var(--disabled-text);
    }

}
.color-disabled{
    color: var(--disabled-text)
}
.border-custom.disabled:hover {
    border-color: #E6E6E6!important;
}
.border-custom.disabled {
    border: 2px solid #E6E6E6!important;
}
a.disabled{
    cursor: not-allowed!important;
}
.description {
    color: var(--description-text);
    font-size: 14px;
}

a.border-custom {
    display: block;
}

a.border-custom:hover {
    border-color: #cccccc;
}